import React,{useEffect, useMemo} from'react';
import {
  meData,
  meSupportExperience,
  meIsAccountAdmin,
  meEntitlements,
  isProductFound,
  meUnifiedAdminFlag,
  meHideAdminSection,
  meHideBillingSection} from 'modules/me-module';
import { prodArray } from 'lib/product-specs';
import QuickLinksView from '../components/quick-link-view';
import { useSelector } from 'react-redux';
import { meIsBillingAdmin,sterlingAccountStatusGet,sterlingAccountStatus, billingIsLoading } from 'modules/billing-module';
import { useDispatch } from 'react-redux';
import { getAccountKey, getLicenses,getBillingAdmin } from 'modules/extadmin-module';

export const QuickLinksContainer =() =>{
  const {
    supportExperience ,
    meData ,
    meIsAccountAdmin,
    meIsBillingAdmin,
    isProductFound,
    unifiedAdminFlag,
    hideAdminSection,
    hideBillingSection,
    sterlingAccountStatus,
    getAccountKey,
    getLicenses,
    getBillingAdmin,
    isLoading,
    meEntitlements
  } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();

  useEffect(()=>{
    //check if the user is sterling user and billing admin
    if(getBillingAdmin && getLicenses.length == 1 && getLicenses[0].channel == "offline" ){
      if(getAccountKey){
        dispatch(sterlingAccountStatusGet(getAccountKey));
      }
    }
  },[getAccountKey,getLicenses,getBillingAdmin,dispatch]);

  useEffect(() => {
    const { emails, id } = meData || {};
    const filteredEntitlement = meEntitlements.filter(entitlement =>
      prodArray.includes(entitlement)
    );
    const primaryEmail = emails?.find(email => email.type === 'primary')?.value || '';
    const accountKey = getAccountKey;
  
    if (accountKey) {
      const userInfo = {
        product_list: filteredEntitlement,
        email: primaryEmail,
        user_key: id,
        account_key: accountKey,
      };
  
      if (!sessionStorage.getItem('as_user_info')) {
        sessionStorage.setItem('as_user_info', JSON.stringify(userInfo));
      }
    }
  }, [meData, meEntitlements, getAccountKey]);

  return(
    <QuickLinksView
      hideAdminSection={hideAdminSection}
      hideBillingSection={hideBillingSection}
      meData={meData}
      supportExperience={supportExperience}
      unifiedAdminFlag={unifiedAdminFlag}
      meIsAccountAdmin={meIsAccountAdmin}
      meIsBillingAdmin={meIsBillingAdmin}
      isProductFound={isProductFound}
      accountType={sterlingAccountStatus}
      isSterlingBillingAdmin={getBillingAdmin}
      isLoading={isLoading}
    />
  );
};

export const getValuesFromStore =(state) =>({
  meEntitlements: meEntitlements(state),
  meData: meData(state),
  supportExperience: meSupportExperience(state),
  meIsAccountAdmin: meIsAccountAdmin(state),
  meIsBillingAdmin: meIsBillingAdmin(state),
  isProductFound : isProductFound(state),
  unifiedAdminFlag: meUnifiedAdminFlag(state),
  hideAdminSection: meHideAdminSection(state),
  hideBillingSection: meHideBillingSection(state),
  sterlingAccountStatus: sterlingAccountStatus(state),
  getAccountKey: getAccountKey(state),
  getLicenses: getLicenses(state),
  getBillingAdmin: getBillingAdmin(state),
  isLoading: billingIsLoading(state),
});

export default QuickLinksContainer;